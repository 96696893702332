<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Defect</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <Form :data="defect"></Form>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="createDefect"
            >
              <CIcon name="cil-check-circle" />
              Create
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import DefectAPI from "/app/src/api/defect.js";

import Form from "./Form";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Create",
  components: { Form },
  data: () => {
    return {
      defectAPI: new DefectAPI(),
      defect: {
        part_id: null,
        product_id: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {},
  methods: {
    createDefect: function () {
      let self = this;

      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");
      this.defect.part_id = 1;
      this.defect.product_id = 1;
      this.defectAPI
        .create(this.defect)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/defects/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.defect.product_id < 1) return "Product is required";
      if (this.defect.part_id < 1) return "Part is required";
      return true;
    },
  },
};
</script>
