<template>
  <CRow>
    <CCol sm="12">
      <CSelect
        label="Status"
        placeholder="select"
        :options="[
          { label: 'Open', value: 'open' },
          { label: 'Repaired', value: 'repaired' },
          { label: 'Cancelled', value: 'cancelled' },
        ]"
        :value.sync="form.status"
      />
    </CCol>
    <CCol sm="6">
      <CSelect
        label="Product"
        placeholder="select"
        :options="products"
        :value.sync="form.product_id"
      />
    </CCol>

    <CCol sm="6">
      <CSelect
        label="Part"
        placeholder="select"
        :options="parts"
        :value.sync="form.part_id"
      />
    </CCol>

    <CCol sm="12">
      <CTextarea
        name="note"
        placeholder="Enter a note"
        description="Enter a note for the defect."
        v-model="form.note"
      />
    </CCol>
  </CRow>
</template>

<script>
import ProductAPI from "/app/src/api/product.js";
import PartAPI from "/app/src/api/part.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Create",
  props: ["data"],
  data: () => {
    return {
      productAPI: new ProductAPI(),
      partAPI: new PartAPI(),
      products: [],
      parts: [],
      form: {},
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {
    this.getProducts();
    this.getParts();
    this.form = this.data;
  },
  methods: {
    getProducts: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.productAPI
        .all()
        .then((products) => {
          for (let i = 0; i < products.length; i++) {
            if ("location" in products[i]) {
              products[
                i
              ].label = `#${products[i].id} - ${products[i].type} - ${products[i].location.name}`;
              products[i].value = products[i].id;
            }
          }

          this.$store.dispatch("stopLoading");

          self.products = products;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
        });
    },
    getParts: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.partAPI
        .all()
        .then((parts) => {
          for (let i = 0; i < parts.length; i++) {
            parts[i].label = parts[i].name;
            parts[i].value = parts[i].id;
          }

          this.$store.dispatch("stopLoading");

          self.parts = parts;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
        });
    },
    validateSubmission: function () {
      if (this.form.product_id < 1) return "Product is required";
      if (this.form.part_id < 1) return "Part is required";
      return true;
    },
  },
  watch: {
    // whenever question changes, this function will run
    data(val, oldVal) {
      this.form = val;
    },
  },
};
</script>
